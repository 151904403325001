import { useState } from 'react';

export default function useToken() {
    const getUserProfile = () => {
        const userString = localStorage.getItem('userProfile');
        const userToken = JSON.parse(userString);
        if (userToken != null) {
            return userToken;
        }
        return userToken
    };

    const [userProfile, setUserProfile] = useState(getUserProfile());

    const saveUserProfile = userProfile => {
        localStorage.setItem('userProfile', JSON.stringify(userProfile));
        setUserProfile(userProfile);
    };

    return {
        setUserProfile: saveUserProfile,
        userProfile
    }

}